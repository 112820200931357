import { useLayout } from "@/lib/preFetch/preFetchLayout";
import { ContentLoader, Link, NextImage } from "@jog/react-components";
import { Container, HR } from "components";
export const Footer = () => {
    var _a;
    const layout = useLayout();
    const footer = (_a = layout === null || layout === void 0 ? void 0 : layout.layout) === null || _a === void 0 ? void 0 : _a.footer;
    if (!footer)
        return (<div className="w-full bg-white py-10">
                <Container>
                    <ContentLoader width="100%" height="100%"/>
                </Container>
            </div>);
    return (<div className="w-full bg-white py-10 px-4 lg:px-0">
            <Container>
                <HR className="mb-10 border-t border-gray-silver"/>
                <div className="flex flex-wrap lg:flex-nowrap">
                    {footer.columns.map((col, colIndex) => (<div className="basis-full lg:basis-3/12" key={colIndex}>
                            <h4 className="text-22 font-bold capitalize text-gray-base">
                                <Link className="text-gray-base hover:text-orange-main" to={col.url}>
                                    {col.title}
                                </Link>
                            </h4>
                            <ul className="hidden list-none p-0 sm:block">
                                {col.items.map((item, index) => (<li className="py-2 px-0 text-base" key={index}>
                                        <Link className="text-gray-base hover:text-orange-main" to={item.url}>
                                            {item.text}
                                        </Link>
                                    </li>))}
                            </ul>
                        </div>))}
                </div>
                <HR className="my-10 border-t border-gray-silver"/>
                <div className="flex flex-wrap lg:flex-nowrap">
                    {footer.logos.map((col, colIndex) => (<div className="basis-full xl:basis-6/12" key={`logos_${colIndex}`}>
                            {col.map((logo, index) => (<span className="mr-6.25 mb-7.5 inline-block lg:mb-0" key={index}>
                                    <NextImage blur={false} src={logo.src} alt="Footer logo" width={(logo.width / logo.height) * 35} height={35}/>
                                </span>))}
                        </div>))}
                    {footer.legal.map((col, colIndex) => (<div className="basis-full xl:basis-6/12" key={`legal_${colIndex}`}>
                            <div className="text-left sm:text-right">
                                {col.map((legal, index) => (<Link className="block whitespace-nowrap py-2.5 px-0 text-base text-gray-base hover:text-orange-main sm:inline-block sm:py-0 sm:px-3.75" href={legal.url} key={index}>
                                        {legal.label}
                                    </Link>))}
                            </div>
                        </div>))}
                </div>
            </Container>
        </div>);
};
