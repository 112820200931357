import { localStorage, recaptchaValidateToken, setPopupModalDisplayTime, validateEmail, } from "@jog/shared";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useI18n, usePopup, useValidateEmail } from "../../hooks";
export const useNewsLetter = ({ source, apis: { postNewsletter }, newsletterUrl, afterSubmit, }) => {
    const { popup } = usePopup();
    const { emailDomainBlackList, emailEndingWhiteList } = useValidateEmail();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [errorMessage, setErrorMessage] = useState(null);
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [pending, setPending] = useState(false);
    const [value, setValue] = useState(" ");
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [checkedOptions, setCheckedOptions] = useState({});
    useEffect(() => {
        setCheckedOptions((popup === null || popup === void 0 ? void 0 : popup.campaignPopupOptionsList.reduce((pre, cur) => ({ ...pre, [cur.key]: 0 }), {})) || {});
    }, [popup === null || popup === void 0 ? void 0 : popup.campaignPopupOptionsList]);
    const redirectToNewsletter = async () => {
        setPending(true);
        setIsButtonDisabled(true);
        try {
            if (!executeRecaptcha) {
                console.log("Execute recaptcha not yet available");
                return;
            }
            const token = await executeRecaptcha("susbcribeToNewsletter");
            const successful = await recaptchaValidateToken(token).then((res) => {
                const { success, score } = res.body;
                return success && score > 0.3;
            });
            if (successful) {
                const interests = Object.entries(checkedOptions)
                    .map(([key, value]) => `${key}=${value}`)
                    .join("&");
                localStorage.setItem("newsletter", {
                    isSubscribed: true,
                    date: dayjs().toString(),
                });
                await postNewsletter(newsletterUrl, value, source, interests);
                afterSubmit === null || afterSubmit === void 0 ? void 0 : afterSubmit();
                setPopupModalDisplayTime();
            }
            else {
                setErrorMessage("Captcha validation was not successful");
            }
        }
        catch (error) {
            setErrorMessage("We could not validate your request please try again later.");
        }
        finally {
            setIsButtonDisabled(false);
            setPending(false);
        }
    };
    const onChange = (value) => {
        const isValidEmail = validateEmail(value, emailDomainBlackList, emailEndingWhiteList);
        setIsButtonDisabled(!isValidEmail);
        setInvalidEmail(!isValidEmail);
        setValue(value);
    };
    const onSubmit = () => validateEmail(value, emailDomainBlackList, emailEndingWhiteList)
        ? redirectToNewsletter()
        : setInvalidEmail(true);
    const onCheckBox = (value, checked) => {
        setCheckedOptions((checkedOptions) => ({
            ...checkedOptions,
            [value]: checked ? 1 : 0,
        }));
    };
    const disabled = useMemo(() => isButtonDisabled || !value, [isButtonDisabled, value]);
    return {
        onChange,
        onSubmit,
        pending,
        errorMessage,
        value,
        invalidEmail,
        onCheckBox,
        disabled,
    };
};
export const NewsLetterFormBase = ({ Input, Button, AlertBox, LogoLoader }) => {
    const NewsLetterForm = ({ newsletterUrl, source, afterSubmit, hideModal, apis }) => {
        var _a;
        const { $t } = useI18n();
        const { popup } = usePopup();
        const { onChange, onSubmit, pending, errorMessage, value, invalidEmail, onCheckBox, disabled } = useNewsLetter({
            source,
            apis,
            newsletterUrl,
            afterSubmit,
        });
        const emailInputId = `newsInput-${source}`;
        return (<form className="flex flex-col" onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
            }}>
                {pending && <LogoLoader />}
                {errorMessage && <AlertBox isError={true} text={$t(errorMessage)}/>}
                <div className="mt-2 flex flex-col text-gray-newsLetter xs:mt-5">
                    <label className="mb-1 text-left font-medium" htmlFor={emailInputId}>
                        {$t("E-mail address")} *
                    </label>
                    <Input aria-label="email" value={value} name="email" type="email" className="w-75 !xs:h-11 !h-8 rounded-none border border-gray-newsLetter p-1.5 !text-left text-sm xs:py-2.5 xs:pl-2.5 xs:pr-5" onChange={(e) => onChange(e.target.value)} placeholder={$t("E-mail address")} id={emailInputId} required/>
                    {invalidEmail && <div className="mt-1 text-right text-sm">{$t("Invalid email address")}</div>}
                </div>
                <div className="flex flex-col flex-wrap text-gray-newsLetter">
                    <p className="mb-1 text-left sm:font-medium">{popup === null || popup === void 0 ? void 0 : popup.campaignPopupOptionsText}</p>
                    {(popup === null || popup === void 0 ? void 0 : popup.campaignPopupActivateFilterOptions) && (<div className="flex flex-col gap-1 xs:gap-3">
                            {(_a = popup === null || popup === void 0 ? void 0 : popup.campaignPopupOptionsList) === null || _a === void 0 ? void 0 : _a.map((option, index) => (<div className="flex items-center text-xs xs:text-base" key={index}>
                                    <input className="before:!bg-transparent" type="checkbox" id={`custom-checkbox-${index}`} value={option.key} onChange={(e) => onCheckBox(e.target.value, e.target.checked)}/>
                                    <label className="font-13 align-middle font-medium" htmlFor={`custom-checkbox-${index}`}>
                                        {option.value}
                                    </label>
                                </div>))}
                        </div>)}
                </div>
                <div className="mt-2 flex flex-col items-start justify-center xs:mt-5">
                    <Button bgColor={popup === null || popup === void 0 ? void 0 : popup.campaignPopupButtonBgColor} bgColorHover={popup === null || popup === void 0 ? void 0 : popup.campaignPopupButtonBgColor} color={popup === null || popup === void 0 ? void 0 : popup.campaignPopupButtonColor} className="flex w-full items-center justify-center font-semibold" disabled={disabled}>
                        {popup === null || popup === void 0 ? void 0 : popup.campaignPopupButtonText}
                    </Button>
                    <div className="mt-3 cursor-pointer text-xs italic text-gray-newsLetter underline" onClick={hideModal}>
                        {popup === null || popup === void 0 ? void 0 : popup.campaignCancelText}
                    </div>
                </div>
            </form>);
    };
    return NewsLetterForm;
};
