import { styled } from "@jog/theming";
const PriceElementWrapper = styled.div `
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    font-size: ${(props) => props.fontSize || "16px"};
    span {
        font-family: ${(props) => props.theme.fontFamily.primary};
        font-weight: normal;
        color: ${(props) => props.priceColor || props.theme.color.draculaGray};
    }
`;
export default PriceElementWrapper;
