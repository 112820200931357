import { useI18n } from "@jog/react-components";
import { hasNumber } from "@jog/shared";
import { useCallback, useState } from "react";
export const StoreLocatorSearch = ({ onSelect, onSearch, }) => {
    const [inputVal, setInputVal] = useState("");
    const [isInputError, setIsInputError] = useState(false);
    const { $t } = useI18n();
    const searchStores = useCallback(() => {
        if (inputVal.length > 2) {
            const isNumberInVal = hasNumber(inputVal);
            if (isNumberInVal) {
                const charactersCount = inputVal.replace(/[0-9]/g, "").length;
                const numbersCount = inputVal.length - charactersCount;
                const isOnlyFourNumbers = /^\d{4}$/.test(inputVal.trim());
                if (numbersCount !== 4) {
                    // less or more than 4 numbers => invalid zip code
                    setIsInputError(true);
                }
                else {
                    if (isOnlyFourNumbers) {
                        // Only 4 numbers are entered
                        const zipCode = inputVal + ",NL";
                        onSearch(zipCode);
                    }
                    else {
                        const isStartingWithFourNumbers = /^\d{4}/.test(inputVal.trim());
                        if (isStartingWithFourNumbers) {
                            onSearch(inputVal);
                        }
                        else {
                            // if zipcode doesn't contain exatly 4 numbers at the start show error
                            setIsInputError(true);
                        }
                    }
                }
            }
            else {
                // more than 3 characters, no number among them submit data
                onSearch(inputVal);
            }
        }
        else {
            // less than 3 characters
            setIsInputError(true);
        }
    }, [onSearch, inputVal]);
    const onChange = useCallback((event) => {
        const inputVal = event.target.value;
        if (!inputVal) {
            onSearch("");
            return;
        }
        if (inputVal.length > 2)
            setIsInputError(false);
        setInputVal(inputVal);
    }, [onSearch]);
    const onSubmit = useCallback((event) => {
        searchStores();
        event.preventDefault();
    }, [searchStores]);
    return (<div className="mb-4 bg-white p-5 text-gray-base md:py-5 md:px-10 ">
            <form className="w-full text-base" onSubmit={onSubmit}>
                <div className="relative w-full sm:w-[45%]">
                    <input className="h-[46px] w-full border border-gray-light p-3 align-top text-base text-gray-base outline-none placeholder:text-gray-silver focus:border-gray-shuttle focus:pl-3 disabled:bg-gray-bg" type="text" placeholder={$t("Enter a zip code or city name")} onChange={onChange}/>
                    <button type="submit" className="absolute right-0 top-0 flex h-[46px] cursor-pointer items-center justify-center px-4 text-center font-icomoonJC text-lg text-gray-silver before:content-['\e906']"/>
                    {isInputError && (<div className="mt-1 text-base text-gray-main">
                            {$t("Enter at least 3 punctuation marks or valid zip code")}
                        </div>)}
                </div>

                <div className="mt-6 flex flex-wrap gap-6">
                    <div>
                        <input className="cursor-pointer" type="checkbox" id="jongensMeisjes" name="jongensMeisjes" onChange={() => onSelect("jongensMeisjes")}/>
                        <label className="cursor-pointer" htmlFor="jongensMeisjes">
                            {$t("Boys & girls")}
                        </label>
                    </div>
                    <div>
                        <input className="cursor-pointer" type="checkbox" id="jeansatelier" name="jeansatelier" onChange={() => onSelect("jeansatelier")}/>
                        <label className="cursor-pointer" htmlFor="jeansatelier">
                            {$t("jeans workshop")}
                        </label>
                    </div>
                </div>
            </form>
        </div>);
};
