import { AddedProductPopup, CartPopup } from "@/components/CartPopup";
import { BagIcon } from "@/components/Icon/BagIcon";
import { Favorite } from "@/components/Icon/Favorite";
import { LocationIcon } from "@/components/Icon/LocationIcon";
import { Service } from "@/components/Icon/Service";
import { UserIcon } from "@/components/Icon/UserIcon";
import { useWishlist } from "@/lib/store/wishlist";
import { useStores } from "@/stores";
import { ClientOnly, Link, useGate, useI18n } from "@jog/react-components";
import { newHeaderEvent } from "@jog/shared";
import { observer } from "mobx-react";
import { useSession } from "next-auth/react";
import { useState } from "react";
import { HeaderBasket } from "./_HeaderBasket";
const IconNumber = ({ count }) => {
    if (!count)
        return;
    return (<ClientOnly>
            <div className="absolute -right-2 -top-1.5 flex h-4.5 w-4.5 items-center justify-center rounded-full bg-green-main text-8 font-medium text-white lg:text-xs">
                {count}
            </div>
        </ClientOnly>);
};
export const Servers = () => {
    const { $t } = useI18n();
    const { service, store } = newHeaderEvent();
    return (<div className="flex h-full">
            <Link href="/winkels" onClick={store} className="flex items-center space-x-1 p-0 uppercase text-grey-icon lg:px-2 lg:py-1.25">
                <LocationIcon height={30}/>
                <span className="hidden font-primary text-xs lg:block">{$t("Search store")}</span>
            </Link>
            <Link onClick={service} href="/service" className="flex items-center space-x-1 p-0 uppercase text-grey-icon lg:px-2 lg:py-1.25">
                <Service height={30} width={30}/>
                <span className="hidden font-primary text-xs lg:block">{$t("Service")}</span>
            </Link>
        </div>);
};
export const NavInfo = observer(({ isCartPage }) => {
    var _a, _b;
    const { $t } = useI18n();
    const session = useSession();
    const auth = session.status === "authenticated";
    const { cartStore } = useStores();
    const cartViewBody = (_a = cartStore.cartView) === null || _a === void 0 ? void 0 : _a.body;
    const wishlistCounts = ((_b = useWishlist.use.simpleWishlist()) === null || _b === void 0 ? void 0 : _b.lineItems.length) || 0;
    const [cartShown, setCartShown] = useState(false);
    const { value: newCartPopup } = useGate("cart");
    const { icons } = newHeaderEvent();
    return (<div className="flex h-full justify-end space-x-3 pr-6 lg:space-x-0 lg:pr-0">
            <Link onClick={icons} href={auth ? "/my-account" : "/login"} className="flex items-center space-x-2 p-0 uppercase text-grey-icon lg:px-2 lg:py-1.25">
                <UserIcon width={20} height={30} fill="currentColor"/>
                <span className="hidden font-primary text-xs lg:block">{auth ? $t("My account") : $t("LOGGIN")}</span>
            </Link>
            <Link href="/favorieten" onClick={icons} className="flex items-center space-x-2 p-0 uppercase text-grey-icon lg:px-2 lg:py-1.25">
                <span className="relative">
                    <Favorite height={30} stroke="currentColor"/>
                    <IconNumber count={wishlistCounts}/>
                </span>
                <span className="hidden font-primary text-xs lg:block">{$t("wishlist")}</span>
            </Link>
            <div className="relative flex h-full items-center " onMouseEnter={() => !isCartPage && setCartShown(true)} onMouseLeave={() => setCartShown(false)}>
                <Link href="/cart" onClick={icons} className="flex items-center space-x-2 p-0 uppercase text-grey-icon lg:px-2 lg:py-1.25">
                    <span className="relative">
                        <BagIcon height={28} width={22} fill="currentColor"/>
                        <IconNumber count={cartViewBody && cartStore.cartTotalItems}/>
                    </span>
                    <span className="hidden font-primary text-xs lg:block">{$t("SHOPPING BASKET")}</span>
                </Link>
                {newCartPopup ? (<CartPopup className="absolute right-0 top-full z-[7] hidden border lg:block" show={cartShown}/>) : (cartShown && (<div className="absolute -right-4 bottom-0">
                            <div className="absolute right-1.75 bottom-0 z-[7] hidden translate-y-full cursor-auto border border-black bg-white text-black lg:block">
                                <ClientOnly>
                                    <HeaderBasket />
                                </ClientOnly>
                            </div>
                        </div>))}
            </div>
            <div className="relative h-full translate-y-[40px]">
                <AddedProductPopup className="absolute right-0 top-full z-[7] border"/>
            </div>
        </div>);
});
