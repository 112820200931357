import { useStores } from "@/stores";
import { useI18n } from "@jog/react-components";
import { HR, TotalPrice } from "components";
import PriceElement from "./PriceElement";
const CheckoutCartFooter = () => {
    var _a, _b, _c, _d, _e, _f;
    const { $t } = useI18n();
    const { cartStore } = useStores();
    const cartViewBody = (_a = cartStore.cartView) === null || _a === void 0 ? void 0 : _a.body;
    const cartPrices = cartStore.getCartPrices;
    return (<div className="mt-10 grid grid-cols-2">
            <div className="col-span-2 md:col-span-1 md:col-start-2 lg:col-span-2">
                <PriceElement title={$t("Subtotal")} priceAmount={cartPrices.subTotalPrice}/>

                {cartPrices.totalDiscount > 0 && (<PriceElement title={$t("Discount")} priceAmount={cartPrices.totalDiscount}/>)}

                {((_c = (_b = cartViewBody === null || cartViewBody === void 0 ? void 0 : cartViewBody.shippingCosts) === null || _b === void 0 ? void 0 : _b.costs) === null || _c === void 0 ? void 0 : _c.centAmount) > 0 && (<PriceElement title={$t("Shipping costs")} priceAmount={(_d = cartViewBody === null || cartViewBody === void 0 ? void 0 : cartViewBody.shippingCosts) === null || _d === void 0 ? void 0 : _d.costs.centAmount}/>)}

                {((_e = cartViewBody === null || cartViewBody === void 0 ? void 0 : cartViewBody.paymentCosts) === null || _e === void 0 ? void 0 : _e.centAmount) > 0 && (<PriceElement title={$t("Payment shipping cost")} priceAmount={(_f = cartViewBody === null || cartViewBody === void 0 ? void 0 : cartViewBody.paymentCosts) === null || _f === void 0 ? void 0 : _f.centAmount}/>)}
                <HR className="border-gray-silver"/>
                <TotalPrice cartViewBody={cartViewBody}/>
            </div>
        </div>);
};
export default CheckoutCartFooter;
