import { Price } from "@/components";
import { Search } from "@/components/Icon/Search";
import { Link, NextImage, getProductPrice, getProductUrl, useI18n, useNavSearch } from "@jog/react-components";
import { useTheme } from "@jog/theming";
const SearchItem = ({ item }) => {
    const theme = useTheme();
    const { firstPrice, hasDiscount } = getProductPrice(item);
    const pdpUrl = getProductUrl(item);
    return (<Link to={pdpUrl}>
            <div className="flex items-center px-3 py-5 text-sm hover:bg-gray-bg hover:text-orange-main">
                <div className="p-2">
                    <NextImage blur={false} className="my-0 mx-auto block" height={80} width={54} src={item.image} alt={item.title}/>
                </div>
                <div className="h-25 p-2">
                    <div>{item.title}</div>
                    <div className="mt-1.5">
                        {hasDiscount && Number(firstPrice) > Number(item.price) ? (<div className="flex flex-col gap-2">
                                <Price color={theme.color.mainGray} fontSize="13px" isOldPrice isListerPrice price={firstPrice}/>
                                <Price color={theme.color.mainRed} fontSize="18px" isListerPrice price={item.price}/>
                            </div>) : firstPrice && Number(firstPrice) > Number(item.price) ? (<div className="flex flex-col gap-2">
                                <Price color={theme.color.mainGray} fontSize="13px" isOldPrice isListerPrice price={firstPrice}/>
                                <Price color={theme.color.mainRed} fontSize="18px" isListerPrice price={item.price}/>
                            </div>) : (<Price price={item.price} fontSize="18px" isListerPrice/>)}
                    </div>
                </div>
            </div>
        </Link>);
};
export const NavSearch = () => {
    const { $t } = useI18n();
    const { handleSearch, onBlur, onFocus, suggestions, items, isSearchShown, searchTerm, setSearchTerm } = useNavSearch({
        itemLength: 3,
        suggestionLength: 5,
    });
    return (<div className="relative flex h-full items-center justify-end">
            <form className="flex w-full border-b-grey-icon bg-gray-light p-2 lg:relative lg:ml-4 lg:w-auto lg:border-b lg:bg-white lg:p-0" onSubmit={async (e) => {
            e.preventDefault();
            await handleSearch();
        }}>
                <div className="cursor-pointer bg-green-main p-1 lg:bg-white lg:p-1.5" onClick={() => handleSearch(searchTerm)}>
                    <Search className="h-5 w-5 fill-grey-usp text-white lg:h-4 lg:w-4 lg:text-grey-icon"/>
                </div>
                <input type="text" placeholder={$t("Find inspiration or products")} className="w-ful flex-1 pl-2 text-grey-icon focus:outline-0 lg:p-0" onChange={(e) => setSearchTerm(e.target.value)} onBlur={onBlur} onFocus={onFocus}/>
            </form>
            {(suggestions.length > 0 || items.length > 0) && isSearchShown && (<div className="absolute right-0 top-11 w-full overflow-x-hidden border border-gray-medium bg-white lg:top-[50px] lg:w-[400px]">
                    {suggestions.length > 0 && (<div className="border-b border-gray-medium">
                            {suggestions.map((item, index) => (<div className="cursor-pointer p-3 hover:bg-gray-bg hover:text-orange-main" key={index} onClick={() => handleSearch(item.title)}>
                                    {item.title}
                                </div>))}
                        </div>)}
                    {items.map((item) => (<SearchItem item={item} key={item.itemno}/>))}
                </div>)}
        </div>);
};
