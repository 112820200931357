import { getDutchAddress } from "@/lib/jog";
import { useStores } from "@/stores";
import { FormikInputField, useI18n, usePending } from "@jog/react-components";
import { checkLength, nameValidation, navigate, onlyDigitsMaxLength, parsePhoneNumberByCountryCode, passwordValidation, phoneValidation, postCodeValidation, scrollToWithOffset, } from "@jog/shared";
import { FormikFormWrapper, styled, useTheme } from "@jog/theming";
import { AlertBox, Button, FormikSelectField } from "components";
import { Form, Formik } from "formik";
import { useCallback, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
export const SignUpForm = () => {
    const theme = useTheme();
    const { $t } = useI18n();
    const { userStore } = useStores();
    const { setPending } = usePending();
    const createNewUser = useCallback(async (userInfo) => {
        try {
            setPending(true);
            await userStore.createNewUser(userInfo);
            window.scrollTo({ top: 0, behavior: "smooth" });
            // if call was success
            if (!userStore.isRegistrationError) {
                navigate(`/login`);
            }
        }
        catch (e) {
            console.error(e);
        }
        finally {
            setPending(false);
        }
    }, [setPending, userStore]);
    const initialValues = useMemo(() => ({
        country: process.env.NEXT_PUBLIC_COUNTRY || "select",
        gender: "select",
        firstName: "",
        middleName: "",
        lastName: "",
        postCode: "",
        streetName: "",
        streetNumber: "",
        additionalStreetInfo: "",
        city: "",
        telNum: "",
        email: userStore.unregisteredEmail,
        password: "",
        confirmPassword: "",
    }), [userStore.unregisteredEmail]);
    const getAddress = useCallback(async (values) => {
        try {
            setPending(true);
            const address = await getDutchAddress(values.postCode, values.streetNumber, values.additionalStreetInfo);
            setPending(false);
            return address;
        }
        catch {
            setPending(false);
            return null;
        }
    }, [setPending]);
    return (<SignUpFormWrapper>
            <Formik initialValues={initialValues} validate={(values) => {
            const errors = {};
            if (values.gender === "select") {
                errors.gender = $t("Select a gender");
            }
            if (values.country === "select") {
                errors.country = $t("Select a country");
            }
            if (!nameValidation(values.firstName)) {
                errors.firstName = $t("Enter your first name here with first letter capital");
            }
            if (!nameValidation(values.lastName)) {
                errors.lastName = $t("Enter your last name here with first letter capital");
            }
            if (!postCodeValidation(values.postCode, values.country)) {
                errors.postCode = $t("Enter a correct zip code");
            }
            if (!onlyDigitsMaxLength(values.streetNumber)) {
                errors.streetNumber = $t("Enter your street number");
            }
            if (!checkLength(values.streetName)) {
                errors.streetName = $t("Enter your street name here");
            }
            if (!checkLength(values.city)) {
                errors.city = $t("Enter your location here");
            }
            if (!phoneValidation(values.telNum, false, [values.country])) {
                errors.telNum = $t("Enter valid telephone number");
            }
            if (!passwordValidation(values.password)) {
                errors.password = $t("Minimum 6 characters");
            }
            if (!passwordValidation(values.confirmPassword)) {
                errors.confirmPassword = $t("Minimum 6 characters");
            }
            else if (values.password !== values.confirmPassword) {
                errors.confirmPassword = $t("Password does not match");
            }
            return errors;
        }} onSubmit={(values) => createNewUser({
            ...values,
            telNum: parsePhoneNumberByCountryCode(values.telNum, values.country),
        })}>
                {({ values, errors, setFieldValue }) => (<Form>
                        {userStore.isRegistrationError && (<AlertBox isError={true} text={$t("Something went wrong please try again")}/>)}
                        <FormikFormWrapper>
                            <Row>
                                <Col sm="12">
                                    {/* COUNTRY */}
                                    <Row className="mb-8">
                                        <FormikSelectField id="country" label={`${$t("Country")} *`} name="country" smRight="4">
                                            <option disabled value="select">
                                                {$t("Country")}
                                            </option>
                                            <option value="BE">{$t("Belgium")}</option>
                                            <option value="NL">{$t("Nederland")}</option>
                                            <option value="DE">{$t("Duitsland")}</option>
                                            <option value="LU">{$t("Luxemburg")}</option>
                                        </FormikSelectField>
                                    </Row>

                                    {/* Gender */}
                                    <Row css={{ marginBottom: "20px" }}>
                                        <FormikSelectField id="gender" label={`${$t("Sex")} *`} name="gender" smRight="4">
                                            <option disabled value="select">
                                                {$t("Select")}
                                            </option>
                                            <option value="MALE">{$t("Male")}</option>
                                            <option value="FEMALE">{$t("Female")}</option>
                                        </FormikSelectField>
                                    </Row>

                                    {/* First Name */}
                                    <Row css={{ display: "flex", flexWrap: "wrap", marginBottom: "20px" }}>
                                        <FormikInputField id="firstName" fieldName="firstName" label={`${$t("First Name")} *`}/>
                                    </Row>

                                    {/* Tussen */}
                                    <Row css={{ display: "flex", flexWrap: "wrap", marginBottom: "20px" }}>
                                        <FormikInputField id="middleName" fieldName="middleName" smRight="2" label={$t("insertion")}/>
                                    </Row>

                                    {/* Last Name */}
                                    <Row css={{ display: "flex", flexWrap: "wrap", marginBottom: "20px" }}>
                                        <FormikInputField id="lastName" fieldName="lastName" label={`${$t("Last Name")} *`}/>
                                    </Row>

                                    {/* Postcode */}
                                    <Row css={{ display: "flex", flexWrap: "wrap", marginBottom: "20px" }}>
                                        <FormikInputField id="postCode" fieldName="postCode" smRight="2" label={`${$t("Postcode")} *`} inputBlur={async () => {
                if (values.country !== "NL" || errors.postCode || errors.streetNumber)
                    return;
                const address = await getAddress(values);
                if (address) {
                    setFieldValue("city", address.body.city);
                    setFieldValue("streetName", address.body.streetname);
                }
            }}/>
                                    </Row>

                                    <Row css={{ display: "flex", flexWrap: "wrap", marginBottom: "20px" }}>
                                        {/* Street num */}
                                        <FormikInputField id="streetNumber" fieldName="streetNumber" inputMode="numeric" smLeft="4" smRight="2" label={`${$t("Street number")} *`} inputBlur={async () => {
                if (values.country !== "NL" || errors.postCode || errors.streetNumber)
                    return;
                const address = await getAddress(values);
                if (address) {
                    setFieldValue("city", address.body.city);
                    setFieldValue("streetName", address.body.streetname);
                }
            }}/>
                                        {/* Toev */}
                                        <FormikInputField id="additionalStreetInfo" fieldName="additionalStreetInfo" smLeft="2" smRight="2" leftOffset="2" label={$t("Toevoeging")} css={{ paddingLeft: "30px" }}/>
                                    </Row>

                                    {/* Str Name */}
                                    <Row css={{ display: "flex", flexWrap: "wrap", marginBottom: "20px" }}>
                                        <FormikInputField id="streetName" fieldName="streetName" label={`${$t("Street name")} *`}/>
                                    </Row>

                                    {/* City */}
                                    <Row css={{ display: "flex", flexWrap: "wrap", marginBottom: "20px" }}>
                                        <FormikInputField id="city" fieldName="city" label={`${$t("City")} *`}/>
                                    </Row>

                                    {/* Tel num */}
                                    <Row css={{
                display: "flex",
                flexWrap: "wrap",
                marginBottom: "20px",
            }}>
                                        <FormikInputField type="tel" id="telNum" fieldName="telNum" label={$t("Telephone number")}/>
                                    </Row>

                                    {/* Email addr */}
                                    <Row css={{
                display: "flex",
                flexWrap: "wrap",
                marginBottom: "20px",
            }}>
                                        <FormikInputField id="email" fieldName="email" isInputDisabled={true} label={`${$t("E-mail address")} *`}/>
                                    </Row>

                                    {/* Password */}
                                    <Row css={{
                display: "flex",
                flexWrap: "wrap",
                marginBottom: "30px",
            }}>
                                        <FormikInputField id="password" fieldName="password" type="password" autocomplete="new-password" label={`${$t("Password")} *`}/>
                                    </Row>

                                    {/* Confirm Password */}
                                    <Row className="mb-8 flex flex-wrap">
                                        <FormikInputField id="confirmPassword" fieldName="confirmPassword" type="password" autocomplete="new-password" label={`${$t("Confirm password")} *`}/>
                                    </Row>

                                    <Row>
                                        <Col sm="12" className="mb-8 flex justify-end">
                                            <Button className="hover:!bg-orange-main" bgColor={theme.color.mainGreen} color={theme.color.white} type="submit" onClick={() => {
                if (errors) {
                    // Pass the error key name which is the same as field ID
                    scrollToWithOffset(Object.keys(errors)[0]);
                }
                else {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                }
            }}>
                                                {$t("Next step")}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </FormikFormWrapper>
                    </Form>)}
            </Formik>
        </SignUpFormWrapper>);
};
const SignUpFormWrapper = styled.div `
    font-size: 16px;
    label {
        align-self: center;
    }
    .Error {
        text-align: left;
        white-space: nowrap;
        margin-bottom: 0;
    }
    .radio-wrapper {
        display: flex;
        input[type="radio"] {
            width: 20px;
            margin-right: 10px;
            font-size: 16px;
        }
        label {
            margin: 0;
        }
    }
`;
