import { NewsForm } from "@/containers/SocialSection/NewsForm";
import { useSocial } from "@/hooks/useSocial";
import { Link, NextImage } from "@jog/react-components";
import { Container } from "components";
export const SocialSection = () => {
    const socials = useSocial();
    return (<div className="mt-3 w-full bg-white  px-4 pt-10 pb-3 lg:px-0">
            <Container>
                <div className="flex flex-wrap items-center justify-between lg:flex-nowrap">
                    <div className="mb-6 flex items-center lg:mb-0">
                        {socials.map((item, index) => (<Link href={item.href} title="" target="_blank" rel="noopener" key={index} css={{ textDecoration: "none !important" }}>
                                <div className="mr-5 flex md:mr-6 xl:mr-3">
                                    <NextImage blur={false} src={item.image} alt="Social network icon" width={41} height={35}/>
                                </div>
                            </Link>))}
                    </div>
                    <div className="flex flex-wrap items-center lg:flex-nowrap">
                        <div className="mr-8 font-primary text-22 font-bold uppercase text-gray-base xl:mr-8">
                            <span className="block sm:hidden">jc nieuwsbrief</span>
                            <span className="hidden sm:block">schrijf je in voor de nieuwsbrief</span>
                        </div>
                        <NewsForm source="footer"/>
                    </div>
                </div>
            </Container>
        </div>);
};
