import { PriceContainer } from "@/containers/CartContent/CartItem/common";
import { Link, NextImage, useGate, useI18n, useLineItemLocalized } from "@jog/react-components";
import { gtmAdpageRemoveFromCart } from "@jog/shared";
import { ArrowButton, Container, Price, WhiteBox } from "components";
import { observer } from "mobx-react";
import { useStores } from "stores";
const Property = ({ label, children }) => {
    return (<div className="font-primary text-13 font-normal">
            <p className="block font-primary font-normal uppercase text-gray-main">{label}</p>
            {children}
        </div>);
};
const BasketCartItem = observer(({ data }) => {
    const { $t } = useI18n();
    const { gtmStore, cartStore } = useStores();
    const { link, name, promotion } = useLineItemLocalized(data);
    const deleteProduct = async (e) => {
        e.preventDefault();
        await cartStore.deleteCartProduct(data.id, 100);
        gtmStore.gtmRemoveFromCart(data, data.quantity);
        gtmAdpageRemoveFromCart(data, data.quantity);
    };
    if (data.sku === "2400059866011")
        return null;
    return (<div className="border-t border-t-gray-silver py-2.5">
            <div className="flex">
                <div className="shink-0 self-center">
                    <Link to={link} title="" className="block w-full">
                        <NextImage blur={false} height={105} width={70} src={data.image} alt="Cart item image"/>
                    </Link>
                </div>
                <div className="grid flex-1 grid-cols-12 pl-4 lg:flex-auto">
                    <div className="col-span-8">
                        <Link to={link} title={name}>
                            <h6 className="m-0 w-full text-13 hover:text-orange-main">{name}</h6>
                        </Link>
                        <div className="my-1.25 mx-0 grid grid-cols-3 gap-x-0.5">
                            <Property label={$t("Size")}>{data.size}</Property>
                            {data.length && <Property label={$t("Length")}>{data.length}</Property>}
                            <Property label={$t("Quantity")}>{data.quantity}</Property>
                        </div>
                        {promotion && <div className="font-primary text-sm font-medium text-red-main">{promotion}</div>}
                    </div>
                    <div className="col-span-4">
                        <div className="mt-5 ml-2 flex flex-col items-end">
                            <Link to={link} title="" className="mb-5 flex justify-between">
                                <button disabled={cartStore.deleteProductLoading} onClick={(e) => deleteProduct(e)} className="flex border-none bg-none text-center text-13 text-gray-main outline-none disabled:opacity-50">
                                    <NextImage blur={false} width="20" height="20" src="/images/trash.png" alt="Delete" className="mr-1.25 w-5"/>
                                </button>
                            </Link>
                            <PriceContainer data={data}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
});
export const HeaderBasket = observer(() => {
    var _a, _b;
    const { cartStore } = useStores();
    const { $t } = useI18n();
    const { value: newCartPopup } = useGate("cart");
    if (newCartPopup)
        return <></>;
    if (!(cartStore.isCartViewLoaded && cartStore.cartTotalItems > 0)) {
        return (<Container className="mb-17.5 whitespace-nowrap sm:max-xl:min-w-full">
                <WhiteBox className="w-full">
                    <div className="empty-cart">
                        <h2 className="text-[25px] capitalize text-gray-base">
                            {$t("YOUR SHOPPING BASKET IS STILL EMPTY")}
                        </h2>
                        <h4 className="text-sm capitalize text-gray-base">{$t("Go shopping! :-)")}</h4>
                    </div>
                </WhiteBox>
            </Container>);
    }
    return (<div className="flex min-w-[480px] flex-col">
            <div>
                <h2 className="py-3.75 px-5 text-[25px] capitalize text-gray-base">
                    {$t("Shopping Basket")} {`(${cartStore.cartTotalItems})`}
                </h2>
            </div>
            <div>
                <div className="max-h-[390px] overflow-y-auto overflow-x-hidden px-7">
                    {(_a = cartStore.cartLineItems) === null || _a === void 0 ? void 0 : _a.map((item) => (<BasketCartItem data={item} key={item.id}/>))}
                </div>
            </div>
            <div>
                <div className="grid grid-cols-2 px-4 py-7 text-22">
                    <div className="col-start-2">
                        <div className="flex justify-between">
                            <div className="mr-15 font-primary font-bold text-gray-dracula">{$t("Total")}</div>
                            {((_b = cartStore.cartView) === null || _b === void 0 ? void 0 : _b.body) && (<Price fontSize="22px" price={cartStore.cartView.body.total.centAmount}/>)}
                        </div>
                        <Link to="/cart">
                            <ArrowButton className="w-full" margin="20px 0 0 0" inCartFooter={true}>
                                {$t("To Payment")}
                            </ArrowButton>
                        </Link>
                    </div>
                </div>
            </div>
        </div>);
});
