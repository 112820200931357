import { navigate } from "@jog/shared";
import { Container, WhiteBox } from "components";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { useStores } from "stores";
import { SignUpForm } from "./SignUpForm";
const SignUpContentComponent = () => {
    const { userStore } = useStores();
    useEffect(() => {
        userStore.clearRegistrationError();
    }, [userStore]);
    if (!userStore.unregisteredEmail) {
        navigate(`/login`);
        return <></>;
    }
    return (<Container>
            <WhiteBox>
                <div className="flex">
                    <div className="w-full lg:w-10/12">
                        <SignUpForm />
                    </div>
                </div>
            </WhiteBox>
        </Container>);
};
export const SignUpContent = observer(SignUpContentComponent);
