import { useI18n } from "@jog/react-components";
import { getJcCountryConvert, isObjectEmpty } from "@jog/shared";
import { useTheme } from "@jog/theming";
import { ArrowButton } from "components";
import { observer } from "mobx-react";
import { useState } from "react";
import { useStores } from "stores";
import DisableLocationModal from "./DisableLocationModal";
// Refresh page/store after succsesful location retrival
const AccountLocationSharing = () => {
    const { userStore } = useStores();
    const [showRevokeModal, setShowrevokeModal] = useState(false);
    const theme = useTheme();
    const { $t } = useI18n();
    // useEffect(() => {
    //   if (isInitialMount.current) {
    //     isInitialMount.current = false
    //   } else {
    //     // Your useEffect code here to be run on update
    //     navigate('/my-account')
    //     // navigate('/my-account/profile');
    //   }
    // }, [isLocationUpdated])
    const handleGetLocation = async () => {
        try {
            const userLocation = await userStore.getLocation();
            console.log(userLocation);
        }
        catch (error) {
            console.log(error.message);
        }
    };
    const handleRevokeLocationPermissions = async () => {
        await userStore.revokeLocationPermissions();
        setShowrevokeModal(true);
    };
    const toggleRevokeModal = () => setShowrevokeModal((prev) => !prev);
    return (<div>
            {showRevokeModal && <DisableLocationModal hideModal={toggleRevokeModal}/>}
            <h3>{$t("Location sharing")}</h3>
            {!isObjectEmpty(userStore.userLocation) && (<p>
                    {$t("Current location")}:{" "}
                    {$t(getJcCountryConvert(userStore.userLocation.countryCode, userStore.userLocation.countryName))}
                </p>)}
            {userStore.isLocationEnabled ? (<ArrowButton color="white" bgColor={theme.color.monzaRed} className="hover:!bg-orange-main" onClickFunction={handleRevokeLocationPermissions}>
                    {$t("Stop location sharing")}
                </ArrowButton>) : (<ArrowButton color="white" bgColor={theme.color.taraBlue} className="hover:!bg-orange-main" onClickFunction={handleGetLocation}>
                    {$t("Enable location sharing")}
                </ArrowButton>)}
        </div>);
};
export default observer(AccountLocationSharing);
