import { recaptchaValidateToken } from "@jog/shared";
import { useCallback, useMemo, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
export const useGiftcard = (getGiftcard) => {
    const [inputVal, setInputVal] = useState("");
    const [recaptchaErrorMessage, setRecaptchaErrormessage] = useState("");
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [value, setValue] = useState(null);
    const [error, setError] = useState(false);
    const [pending, setPending] = useState(false);
    const getGiftcardValue = useCallback(async (code) => {
        setPending(true);
        setValue(null);
        try {
            const res = await getGiftcard(code);
            if (res && res.statusCode === 200) {
                setValue(res.body.giftcard.value / 100);
                setError(false);
            }
            else {
                setError(true);
            }
        }
        catch (e) {
            setError(true);
        }
        finally {
            setPending(false);
        }
    }, [getGiftcard]);
    const onSubmit = useCallback(async () => {
        const giftcard = inputVal === null || inputVal === void 0 ? void 0 : inputVal.trim();
        if (!giftcard.length)
            return;
        if (!executeRecaptcha) {
            setRecaptchaErrormessage("Execute recaptcha not yet available");
            return;
        }
        setRecaptchaErrormessage("");
        try {
            setPending(true);
            const token = await executeRecaptcha("checkGiftCardValidation");
            const successful = await recaptchaValidateToken(token).then((res) => {
                const { success, score } = res.body;
                return success && score > 0.3;
            });
            if (successful) {
                await getGiftcardValue(giftcard);
            }
            else {
                setRecaptchaErrormessage("ReCaptcha validation was not successful");
            }
        }
        catch (e) {
            setRecaptchaErrormessage("ReCaptcha validation was not successful");
            setPending(false);
        }
    }, [executeRecaptcha, getGiftcardValue, inputVal, recaptchaValidateToken]);
    const isGiftcardError = useMemo(() => error || !!recaptchaErrorMessage, [recaptchaErrorMessage, error]);
    return {
        value,
        error,
        pending,
        onSubmit,
        isGiftcardError,
        setInputVal,
        recaptchaErrorMessage,
    };
};
