import { Button } from "@/components";
import { JCRichText } from "@/components/RichText/RichText";
import { useI18n } from "@jog/react-components";
import { useTheme } from "@jog/theming";
export const ThankyouMessage = ({ hideModal, thankyouTitle, thankyouBody, }) => {
    const theme = useTheme();
    const { $t } = useI18n();
    return (<div className="text-center">
            <h2 className="text-base uppercase text-gray-newsLetter"> {thankyouTitle}</h2>
            <p className="mt-5 mb-10 text-xs">
                <JCRichText field={thankyouBody}/>
            </p>
            <Button bgColor={theme.color.mainGreen} color={theme.color.white} className="w-full hover:!bg-orange-main" onClick={hideModal}>
                {$t("OK")}
            </Button>
        </div>);
};
